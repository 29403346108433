import { Injectable } from '@angular/core';
import { RestService, Rest } from '@abp/ng.core';
import { QuestionSummary } from "../components/widgets/usage/usage-widget.component";
import {
  GetAverageExecutionDurationPerDayInput,
  GetAverageExecutionDurationPerDayOutput
} from '@volo/abp.ng.audit-logging/proxy';
import { firstValueFrom } from 'rxjs';

export interface SaasTenantAddOrUpdateImageDto
{
  image?: File;
}

export interface TenantDto{
  tenantId: string;
  tenantName: string;
}

export interface TenantQuestionStats {
  totalQuestions: number;
  maxQuestions: number;
}

@Injectable({
  providedIn: 'root',
})
export class CustomTenantService {
  apiName = 'SaasHost';

  constructor(private restService: RestService) {}

  addOrUpdateUserImage(id: string, input: SaasTenantAddOrUpdateImageDto, config?: Partial<Rest.Config>){
    const formData: FormData = new FormData();
    if (input.image) {
      formData.append('Image', input.image);

      this.restService.request<any, void>(
        {
          method: 'POST',
          url: `/api/saas/tenants/${id}/user-image`,
          body: formData,
        },
        { apiName: this.apiName, ...config },
      ).subscribe();
    }
  }

  addOrUpdateAssistantImage(id: string, input: SaasTenantAddOrUpdateImageDto, config?: Partial<Rest.Config>) {
    const formData: FormData = new FormData();
    if (input.image) {
      formData.append('Image', input.image);

      this.restService.request<any, void>(
        {
          method: 'POST',
          url: `/api/saas/tenants/${id}/assistant-image`,
          body: formData,
        },
        { apiName: this.apiName, ...config },
      ).subscribe();
    }
  }

  getUserImage = (id: string, config?: Partial<Rest.Config>): Promise<Blob> => {
    return firstValueFrom(this.restService.request<any, Blob>(
      {
        method: 'GET',
        url: `/api/saas/tenants/${id}/user-image`,
        responseType: 'blob' as 'json'
      },
      { apiName: this.apiName, ...config },
    ));
  }


  getAssistantImage = (id: string, config?: Partial<Rest.Config>): Promise<Blob> => {
    return firstValueFrom(this.restService.request<any, Blob>(
      {
        method: 'GET',
        url: `/api/saas/tenants/${id}/assistant-image`,
        responseType: 'blob' as 'json'
      },
      { apiName: this.apiName, ...config },
    ));
  }

  getQuestionSummary = (tenantId: string, startDate: Date, endDate: Date, config?: Partial<Rest.Config>): Promise<QuestionSummary> => {
    return firstValueFrom(this.restService.request<any, QuestionSummary>(
      {
        method: 'GET',
        url: `/api/saas/tenants/question-summary?tenantId=${tenantId}&startDate=${encodeURIComponent(startDate.toISOString())}&endDate=${encodeURIComponent(endDate.toISOString())}`,
        responseType: 'json'
      },
      { apiName: this.apiName, ...config },
    ));
  }

  getTenantQuestionStats = (id?: string, config?: Partial<Rest.Config>): Promise<TenantQuestionStats> => {
    const tenantId = id ?? this.getTenantId();
    if (!tenantId) {
      const result: TenantQuestionStats = {
        totalQuestions: 0,
        maxQuestions: 0
      };
      return Promise.resolve(result);
    }
    return firstValueFrom(this.restService.request<any, TenantQuestionStats>(
      {
        method: 'GET',
        url: `/api/saas/tenants/${tenantId}/question-stats`,
        responseType: 'json'
      },
      { apiName: this.apiName, ...config },
    ));
  }

  getTenantsList = (config?: Partial<Rest.Config>): Promise<TenantDto[]> => {
    return firstValueFrom(this.restService.request<any, TenantDto[]>(
      {
        method: 'GET',
        url: `/api/saas/tenants/tenants-list`,
        responseType:'json'
      },
      { apiName: this.apiName, ...config },
    ));
  }

  getWebClientBaseUrl = (config?: Partial<Rest.Config>): Promise<string> => {
    return firstValueFrom(this.restService.request<any, string>(
      {
        method: 'GET',
        url: `/api/saas/tenants/web-client-base-url`,
        responseType:'text'
      },
      { apiName: this.apiName, ...config },
    ));
  }

  getBlockChatOnLimit = (config?: Partial<Rest.Config>): Promise<boolean> => {
    return firstValueFrom(this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `/api/saas/tenants/block-chat-on-limit`,
      },
      { apiName: this.apiName, ...config },
    ));
  }

  testTenantLogin = (tenantId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TenantDto[]>(
      {
        method: 'POST',
        url: `/api/saas/tenants/test-tenant-login?tenantId=${tenantId}&username=test&password=test`,
        responseType:'json'
      },
      { apiName: this.apiName, ...config },
    );

  testTenant2FALogin = (tenantId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TenantDto[]>(
      {
        method: 'POST',
        url: `/api/saas/tenants/test-tenant-2FA-login?tenantId=${tenantId}&username=test&password=test`,
        responseType:'json'
      },
      { apiName: this.apiName, ...config },
    );

  getAverageChatExecutionDurationPerDay =
    (tenantId: string, filter: GetAverageExecutionDurationPerDayInput):
      Promise<GetAverageExecutionDurationPerDayOutput> => {
        return firstValueFrom(this.restService.request<any, GetAverageExecutionDurationPerDayOutput>({
            method: 'GET',
            url: `/api/saas/tenants/average-chat-execution-duration-per-day`,
            params: { tenantId: tenantId, startDate: filter.startDate, endDate: filter.endDate },
          },
          { apiName: this.apiName }));
    }


  private getTenantId(): string {
    const abpSessionString: string= localStorage.getItem("abpSession");
    if (abpSessionString) {
      const abpSession = JSON.parse(abpSessionString);
      return abpSession.tenant?.id;
    }
    return null;
  }
}
