import { NgModule } from '@angular/core';
import { CustomTenantComponent } from './custom-tenant.component';
import { ExtensibleModule } from '@abp/ng.components/extensible';
import { PageModule } from '@abp/ng.components/page';
import { SharedModule } from '../../shared/shared.module';
import { SaasModule } from '@volo/abp.ng.saas';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavLink, NgbNavOutlet, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Button, ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [CustomTenantComponent],
  imports: [SharedModule, ExtensibleModule, PageModule, SharedModule, SaasModule, FeatureManagementModule, NgbNavOutlet, NgbNav, NgbTooltip, NgbNavContent, NgbNavLink, NgbNavItem, ButtonModule],
  exports: [CustomTenantComponent]
})
export class CustomTenantModule { }
