import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ContextMenuComponent } from '@volosoft/ngx-lepton-x';
import { UserProfileService } from '@volo/ngx-lepton-x.core';
import { HubService } from '../../services/hub-service';
import { UserMenuService } from '@abp/ng.theme.shared';
import { eUserMenuItems } from '@volosoft/abp.ng.theme.lepton-x';
import { CustomTenantService, TenantQuestionStats } from '../../services/custom-tenant.service';


@Component({
  selector: 'app-pm-toolbar-container',
  templateUrl: './toolbar-container.component.html',
})
export class ToolbarContainerComponent {
  @ViewChild(ContextMenuComponent, { static: false })
  ctxMenu!: ContextMenuComponent;
  statText: string= "";
  isLoadingQuestionStats: boolean = false;

  profileRef$ = new ReplaySubject<ElementRef>(1);

  constructor(
    public userProfileService: UserProfileService,
    private userMenu: UserMenuService,
    private customTenantService: CustomTenantService,
  ) {
    this.userMenu.removeItem("Gdpr.GdprNavigation");
    this.userMenu.removeItem(eUserMenuItems.LinkedAccounts);
    this.userMenu.removeItem(eUserMenuItems.SecurityLogs);
    this.userMenu.removeItem(eUserMenuItems.AuthorityDelegation);
    this.userMenu.removeItem(eUserMenuItems.MyAccount);
  }

  toggleCtxMenu(): void {
    this.ctxMenu.toggle();
    if (this.ctxMenu.visible) {
      this.fetchTenantQuestionStats();
    }
  }

  private async fetchTenantQuestionStats(): Promise<void>{
    this.isLoadingQuestionStats = true;
    try {
      const questionStats: TenantQuestionStats = await this.customTenantService.getTenantQuestionStats();
      this.statText = `${questionStats.totalQuestions ?? 0} / ${questionStats.maxQuestions ?? 0}`;
      this.isLoadingQuestionStats = false;
    } catch (error) {
      console.error("Failed to pull user statistics data:", error);
    }
  }
}
